<template>
 <div>
  <svg height="183" viewBox="0 0 183 183" width="183" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.5" x2="0.5" y2="1">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="icon_fietsroute" transform="translate(-685 -3088)">
    <rect :fill="background" data-name="Rectangle 215" height="183" id="Rectangle_215" transform="translate(685 3088)" width="183"/>
    <g data-name="MDI / bike" id="MDI_bike" opacity="0.997" style="isolation: isolate" transform="translate(711.967 3134.748)">
     <g fill="url(#linear-gradient)" id="Boundary" opacity="0" stroke="rgba(0,0,0,0)" stroke-width="1" transform="translate(0)">
      <rect :fill="background" height="89.504" stroke="none" width="89.504"/>
      <rect :fill="background" height="88.504" width="88.504" x="0.5" y="0.5"/>
     </g>
     <path d="M18.647,73.176A13.053,13.053,0,1,1,31.7,60.124,13.053,13.053,0,0,1,18.647,73.176m0-31.7A18.647,18.647,0,1,0,37.294,60.124,18.647,18.647,0,0,0,18.647,41.477m36.548-7.459H70.858V27.305H58.924L51.689,15.11a6.375,6.375,0,0,0-5.445-3.1,6.219,6.219,0,0,0-4.475,1.865L27.97,27.641a6.18,6.18,0,0,0-1.865,4.513,6.3,6.3,0,0,0,3.17,5.482l12.493,7.571V63.853h6.713V39.612l-8.391-6.153,8.652-8.764M70.858,73.176A13.053,13.053,0,1,1,83.91,60.124,13.053,13.053,0,0,1,70.858,73.176m0-31.7A18.647,18.647,0,1,0,89.5,60.124,18.647,18.647,0,0,0,70.858,41.477M59.67,14.626a6.713,6.713,0,1,0-6.713-6.713A6.685,6.685,0,0,0,59.67,14.626Z" data-name="Path / bike" fill="url(#linear-gradient)" id="Path_bike" transform="translate(0 6.258)"/>
    </g>
    <path d="M19.49,5.64l2.271-2.271a2.445,2.445,0,0,1,3.468,0L45.119,23.247a2.445,2.445,0,0,1,0,3.468L25.23,46.6a2.445,2.445,0,0,1-3.468,0L19.49,44.333a2.458,2.458,0,0,1,.041-3.509L31.859,29.079H2.455A2.45,2.45,0,0,1,0,26.624V23.35a2.45,2.45,0,0,1,2.455-2.455h29.4L19.531,9.149A2.441,2.441,0,0,1,19.49,5.64Z" data-name="Icon awesome-arrow-right" fill="url(#linear-gradient)" id="Icon_awesome-arrow-right" opacity="0.997" transform="translate(795.194 3146.445)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>